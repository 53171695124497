
import { useStore } from "vuex";
import { defineComponent, ref, onMounted, computed } from "vue";
import moment from "moment";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";
import "element-plus/theme-chalk/display.css";
export default defineComponent({
  setup() {
    const store = useStore();
    const imgPopupDelete = ref(false);
    const deleteData = ref("");
    const toString = ref("");
    const img = ref(null);
    const isLoadingPage = ref(true);
    const imgPopup = ref(false);
    const isLoading = ref(false);
    const reload = ref(false);
    const route = useRoute();
    const router = useRouter();
    async function init() {
      const id = route.params.bank_managerId;
      isLoading.value = true;
      const result = await store.dispatch(
        `BankModule/${Actions.FETCH_LIST_BANK_DETAIL}`,
        id
      );
      isLoading.value = false;
    }
    async function previewImg(item) {
      imgPopup.value = true;
      img.value = item;
    }
    async function Popupdelete(item) {
      imgPopupDelete.value = true;
      deleteData.value = item;
    }
    async function toDelete(id) {
      toString.value = id.toString();
      isLoadingPage.value = true;
      reload.value = true;
      store.commit("setLoadingGlobal", true);
      const result = await store.dispatch(
        `BankModule/${Actions.DELETE_BANK}`,
        toString.value
      );
      if (result == false) return;
      await store.dispatch(`BankModule/${Actions.FETCH_LIST_BANK}`);
      imgPopup.value = false;
      store.commit("setLoadingGlobal", false);
      isLoadingPage.value = false;
      router.push({
        path: `/management/feature-bank`,
      });
    }
    onMounted(() => {
      init();
    });
    const get_detail_bank = computed(() => {
      return store.state.BankModule.get_detail_bank;
    });

    return {
      moment,
      isLoading,
      router,
      get_detail_bank,
      previewImg,
      imgPopup,
      img,
      imgPopupDelete,
      Popupdelete,
      toDelete,
      isLoadingPage,
      toString,
      reload,
      deleteData,
    };
  },
});
